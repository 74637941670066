import "./App.css";

import Maintenance from "./Components/Maintenance";


function App() {
  return (
    <>
     <Maintenance />
    </>
  );
}

export default App;
